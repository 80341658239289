
import { Link } from 'react-router-dom';
import { useState, useEffect, useMemo } from 'react';
import Sidebar from '../../../components/sidebar';
import {Table} from "../../../components/table";

export default function Developing() {

	const [onSelect, setSelect] = useState();

    useEffect( () => {
        const temp = window.location.pathname;
        var path = temp;
        setSelect(path);
    }, []);


    const cols = useMemo(
        () => [
			{
				header: 'Topic',
				cell: ({cell}) => <Link to={cell.row.original.topicLink} className="text-blue-500 hover:no-underline underline">{ cell.row.original.topic }</Link>,
				accessorKey: 'topic',
			},
			{
				header: 'Description',
				cell: (row) => row.renderValue(),
				accessorKey: 'description',
			},
        ],
        []
    );

    const dummyData = () => {
        const items = [
		{
			topic: "Testing and debugging",
			description: "Guidance on testing and debugging for iOS.",
			topicLink: "./testing-and-debugging",
        },
	];

        return items;
    };

	return (
		<>
			<Sidebar onSelect={onSelect} />

			<div className="flex">

				<main className='my-20 sm:ml-72 sm:px-10 px-10 w-full'>
					
					<h2 className="text-3xl mb-10">Developing</h2>

					<p> This section contains Unity project development with 6uo SDK on topics such as testing and debugging. </p>

                    <img src="/images/crossTheRoad3GamePlaywithSmartphone.png" alt="Cross The Road Gameplay" width={500} />

					<Table data={dummyData()} columns={cols} />

				</main>

			</div>
		</>
	)
}