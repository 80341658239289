
import { useState, useEffect, useMemo } from 'react';
import Sidebar from '../../../components/sidebar';
import {Table} from "../../../components/table";
import { Link } from 'react-router-dom';

export default function GettingStarted() {

	const [onSelect, setSelect] = useState();

    useEffect( () => {
        const temp = window.location.pathname;
        var path = temp;
        setSelect(path);
    }, []);

    const cols = useMemo(
        () => [
			{
				header: 'Topic',
				cell: ({cell}) => <Link to={cell.row.original.topicLink} className="text-blue-500 hover:no-underline underline">{ cell.row.original.topic }</Link>,
				accessorKey: 'topic',
			},
			{
				header: 'Description',
				cell: (row) => row.renderValue(),
				accessorKey: 'description',
			},
        ],
        []
    );

    const dummyData = () => {
        const items = [
		{
			topic: "iOS environment setup",
			description: "Explains how to setup a Unity project with 6uo SDK for iOS development.",
			topicLink: "./ios-environment-setup",
        },
		{
			topic: "SDK settings",
			description: "Reference documentation for 6uo SDK Settings.",
			topicLink: "./sdk-settings",
		},
	];

        return items;
    };

	return (
		<>
			<Sidebar onSelect={onSelect} />
			
			<div className="flex">

				<main className='my-20 sm:ml-72 sm:px-10 px-10 w-full'>
					
					<h2 className="text-3xl mb-10">Getting started with iOS</h2>

					<p> This section explains how to integrate 6uo SDK into an Unity development project and introduces tools and settings that are specific to the iOS platform. </p>

					<Table data={dummyData()} columns={cols} />


				</main>

			</div>
		</>
	)
}