
import { BrowserRouter , Routes , Route } from 'react-router-dom';
import { useEffect, useState } from 'react';

import Footer from "./components/footer";
import Nav from "./components/nav";

import Home from "./pages/home";
import ScripingIndex from "./pages/scripting";
import AndriodPage from "./pages/androidIndex";
import IOSPage from "./pages/iosIndex";
import NotFoundPage from './pages/NotFound';

function App() {

    const [onSelect, setSelect] = useState();

    useEffect( () => {
        const temp = window.location.pathname;
        var path = temp;
        setSelect(path);
    }, []);

	return (
        <BrowserRouter>
            <Nav />
            <div className='min-h-screen dark:bg-black dark:text-gray-300'>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/manual/android/*" element={<AndriodPage />}/>
                    <Route path="/manual/ios/*" element={<IOSPage />}/>
                    <Route path="/scripting/*" element={< ScripingIndex/> }/>
                    <Route path="*" element={<NotFoundPage/>} />
                </Routes>
            </div>
            <Footer/>
        </BrowserRouter>
	);

}

export default App;
