
import { useState, useEffect } from 'react';
import Sidebar from '../../components/sidebar';

export default function TestingAndDebugging() {

	const [onSelect, setSelect] = useState();

    useEffect( () => {
        const temp = window.location.pathname;
        var path = temp;
        setSelect(path);
    }, []);

	return (
		<>
            <Sidebar onSelect={onSelect} />

			<div className="flex">

				<main className='my-20 sm:ml-72 sm:px-10 px-10 w-full'>
					
					<h2 className="text-3xl mb-10"> Testing and debugging </h2>

					To test with 6uo SDK, you may access to <a target="_blank" className='text-blue-500 hover:no-underline underline' href="https://sandbox.6uogames.com">6uo sandbox</a> to register authorization key and advertising ID.

					<img className='my-10' src="/images/sandboxRegister.jpg" alt="6uo Sandbox" width={712}/>

				</main>

			</div>
		</>
	)
}
