import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/scripting_sidebar';
import { Link } from 'react-router-dom';

function ScriptingIndex(  ) {

    const [onSelect, setSelect] = useState();

    useEffect( () => {
        const temp = window.location.pathname;
        var path = temp;
        setSelect(path);
    }, []);

    return (
        <>

            <Sidebar onSelect={onSelect} />

            <div className="flex">

                <main className='my-20 sm:ml-72 sm:px-10 px-10 w-full'>
                    
                    <article className='space-y-8'>
                        <h1 className='text-3xl'>
                            Welcome to the 6uo SDK Documentation
                        </h1>
                        <p>
                            Explore the power of 6uo SDK, a comprehensive solution designed to empower developers in enhancing their mobile games. Whether you&apos;re building for Android or iOS, 6uo SDK offers a robust set of features to elevate your gaming experience.
                        </p>

                        <p className='text-2xl'>
                            Features
                        </p>
                        <li>
                            <div className="inline font-bold">Initialization</div>: Set up your project seamlessly with our easy-to-follow initialization methods for both Android and iOS.
                        </li>
                        <li>
                            <div className="inline font-bold">In-Game Purchase</div>: Implement in-game purchases effortlessly, allowing users to enhance their gaming experience through a smooth and secure transaction process.
                        </li>
                        <li>
                            <div className="inline font-bold">Advertising</div>: Monetize your games with our advertising features, including banner displays, interstitial ads, and rewarded ads, providing flexible options to suit your game&apos;s monetization strategy.
                        </li>
                        <li>
                            <div className="inline font-bold">Monetize Games</div>: Explore strategies to effectively monetize your games, maximizing revenue while ensuring a positive user experience.
                        </li>
                        <li>
                            <div className="inline font-bold">Analytics</div>: Gain valuable insights into user behavior with our analytics tools. Track events, analyze user interactions, and optimize your game for success.
                        </li>
                        <li>
                            <div className="inline font-bold">Reporting</div>: Generate comprehensive reports and view analytics dashboards to monitor the performance of your games. Make informed decisions based on real-time data.
                        </li>

                        <p className='text-2xl'>
                            Get Started
                        </p>
                        <p>
                            Select your platform below to dive into platform-specific details:
                        </p>
                        <li>
                            <a href="/scripting/android">Android</a> - Android-specific features and implementation details. 
                        </li>
                        <li>
                            <a href="/scripting/ios">iOS</a> - iOS-specific features and implementation details.
                        </li>
                        <p>
                            We are excited to support you on your journey to create engaging and profitable games using the 6uo SDK. Let's build something amazing together!
                        </p>
                    </article>

                </main>

            </div>

        </>
    );
}

export default ScriptingIndex