import React from 'react';

export default function NotFound() {
	return (
	<div className="bg-white dark:bg-black py-6 sm:py-8 lg:py-12 h-screen">
		<div className="max-w-screen-2xl px-4 py-16 md:px-8 mx-auto">
		<div className="flex flex-col items-center">

			<a href="/" className="dark:text-gray-200 inline-flex items-center text-black-800 text-2xl md:text-3xl font-bold gap-2.5 mb-8" aria-label="logo">
			<img src='/images/6uologo.png' alt="logo" className="w-12 h-12" />

			6uo Games
			</a>


			<p className="text-teal-500 text-sm md:text-base font-semibold uppercase mb-4">That’s a 404</p>
			<h1 className="text-gray-800 dark:text-gray-200 text-2xl md:text-3xl font-bold text-center mb-2">Page not found</h1>

			<p className="max-w-screen-md text-gray-500 dark:text-gray-400 md:text-lg text-center mb-12">The page you’re looking for doesn’t exist.</p>

			<a href="/" className="inline-block bg-gray-200 hover:bg-gray-300 focus-visible:ring ring-indigo-300 text-gray-500 active:text-gray-700 text-sm md:text-base font-semibold text-center rounded-lg outline-none transition duration-100 px-8 py-3">Go home</a>
		</div>
		</div>
	</div>
	)
}