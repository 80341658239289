
import { useState, useEffect, useMemo } from 'react';
import Sidebar from '../components/sidebar';
import {Table} from "../components/table";
import { Link } from 'react-router-dom';

export default function Android() {

	const [onSelect, setSelect] = useState();

    useEffect( () => {
        const temp = window.location.pathname;
        var path = temp;
        setSelect(path);
    }, []);

    const cols = useMemo(
        () => [
			{
				header: 'Topic',
				cell: ({cell}) => <Link to={cell.row.original.topicLink} className="text-blue-500 hover:no-underline underline">{ cell.row.original.topic }</Link>,
				accessorKey: 'topic',
			},
			{
				header: 'Description',
				cell: (row) => row.renderValue(),
				accessorKey: 'description',
			},
        ],
        []
    );

    const dummyData = () => {
        const items = [
		{
			topic: "Introducing Android",
			description: "Introduces key concepts and workflows that are useful to understand before you integrate 6uoSDK into your Unity application for Android.",
			topicLink: "./introducing",
        },
		{
			topic: "Getting Started",
			description: "Guidance on integrating 6uo SDK into an Unity development project and introduces tools and settings that are specific to the Android platform.",
			topicLink: "./getting-started",
		},
		{
			topic: "Developing",
			description: "Contains Android-specific development information on topics such as testing and debugging.",
			topicLink: "./developing",
		},
	];

        return items;
    };

	return (
		<>
			<Sidebar onSelect={onSelect} />
			
			<div className="flex">

				<main className='my-20 sm:ml-72 sm:px-10 px-10 w-full'>
					
					<h2 className="text-3xl mb-10">Android</h2>

					<p>This section contains information of 6uo SDK development for Android.</p>

					<img src="/images/android.png" width={300} alt="Android" />

					<Table data={dummyData()} columns={cols} />

				</main>

			</div>
		</>
	)
}