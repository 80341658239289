
import { useState, useEffect } from 'react';
import Sidebar from '../../components/sidebar';

export default function AndroidEnvironmentSetup() {

    const [onSelect, setSelect] = useState();

    useEffect( () => {
        const temp = window.location.pathname;
        var path = temp;
        setSelect(path);
    }, []);

	return (
		<>
            <Sidebar onSelect={onSelect} />

			<div className="flex">

				<main className='my-20 sm:ml-72 sm:px-10 px-10 w-full'>
					
					<h2 className="text-3xl mb-10">Android environment setup</h2>

                    <p> To integrate 6uo SDK into your Unity application for Android, you first need to import 6uo SDK package. </p>

                    <h3 className="text-2xl my-5"> Import package </h3>

                    <p> 1. drag 6uoSDK unitypackage into Unity Assets </p>

                    <p> 2. click import </p>

                    <img src="/images/import6uoSDK.jpg" alt="import Unity Package"/>

                    <h3 className="text-2xl my-5"> Setting the Android Target API </h3>

                    1. Go to <div className="font-bold inline">Edit &gt; Project Settings</div>

                    <p> 2. In the Project settings window, select the Player tab, then open Android Player Settings </p>

                    <img src="/images/android_min_api.jpg" alt="Android player setting" />

                    3. Open the <div className="font-bold inline">Other Settings</div> fold-out. In the Identification section, select Minimum API level to be Android 10.0

                    <img src="/images/androidPlayerSetting.jpg" alt="Android Min API level" />
                    
				</main>

			</div>
		</>
	)
}