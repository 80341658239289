import React, { useEffect, useState, useMemo } from 'react';
import Sidebar from '../../../components/scripting_sidebar';


function SDKManager(  ) {

    const [onSelect, setSelect] = useState();

    useEffect( () => {
        const temp = window.location.pathname;
        var path = temp;
        setSelect(path);
    }, []);


    return (
        <>

            <Sidebar onSelect={onSelect} />

            <div className="flex">

                <main className='my-20 sm:ml-72 sm:px-10 px-10 w-full'>
                    
                <article className='space-y-8'>
                    
                    <h1 className='text-3xl'>
                        <p>SDKManager Reference Documentation</p>
                    </h1>

                    <h2 className='text-2xl'>
                        Overview
                    </h2>
                    <p>
                        The &#96;SDKManager&#96;  provides a set of functions to manage the SDK&apos;s functionality, including handling product modes, checking online status, updating and retrieving user balances, login/signup functionality, initializing and interacting with campaigns, and managing in-app purchases.
                    </p>

                    <div className=''>
                        <div className='border-b py-2'>
                            <h2 className='text-2xl'>
                                <p>prodMode</p>
                            </h2>
                        </div>
                        <div className='my-5'>
                            <p>
                                This function sets the API URL to product mode instead of the demo.
                            </p>
                            <h2 className='text-lg my-5 font-bold'>Declaration</h2>
                            <div className='bg-gray-50 p-5 dark:bg-[#363739]'>
                                @interface prodMode: NSObject
                            </div>
                        </div>
                    </div>

                    <div className=''>
                        <div className='border-b py-2'>
                            <h2 className='text-2xl'>
                                <p>get6uoBalance</p>
                            </h2>
                        </div>
                        <div className='my-5'>
                            <p>
                                Returns the user's balance.
                            </p>
                            <h2 className='text-lg my-5 font-bold'>Declaration</h2>
                            <div className='bg-gray-50 p-5 dark:bg-[#363739]'>
                                @interface get6uoBalance: NSObject
                            </div>
                        </div>
                    </div>

                    <div className=''>
                        <div className='border-b py-2'>
                            <h2 className='text-2xl'>
                                <p>email_login</p>
                            </h2>
                        </div>
                        <div className='my-5'>
                            <p>
                                Initiates the login process.
                            </p>
                            <h2 className='text-lg my-5 font-bold'>Declaration</h2>
                            <div className='bg-gray-50 p-5 dark:bg-[#363739]'>
                                @interface email_login: NSObject
                            </div>
                        </div>
                    </div>

                    <div className=''>
                        <div className='border-b py-2'>
                            <h2 className='text-2xl'>
                                <p>checkProtected</p>
                            </h2>
                        </div>
                        <div className='my-5'>
                            <p>
                                Check whether the user is logged in.
                            </p>
                            <h2 className='text-lg my-5 font-bold'>Declaration</h2>
                            <div className='bg-gray-50 p-5 dark:bg-[#363739]'>
                                @interface checkProtected: NSObject
                            </div>
                        </div>
                    </div>

                    <div className=''>
                        <div className='border-b py-2'>
                            <h2 className='text-2xl'>
                                <p>email_signup</p>
                            </h2>
                        </div>
                        <div className='my-5'>
                            <p>
                                Initiate the signup process.
                            </p>
                            <h2 className='text-lg my-5 font-bold'>Declaration</h2>
                            <div className='bg-gray-50 p-5 dark:bg-[#363739]'>
                                @interface email_signup: NSObject
                            </div>
                        </div>
                    </div>

                    <div className=''>
                        <div className='border-b py-2'>
                            <h2 className='text-2xl'>
                                <p>initialize_ad</p>
                            </h2>
                        </div>
                        <div className='my-5'>
                            <p>
                                Initialize the campaign.
                            </p>
                            <h2 className='text-lg my-5 font-bold'>Declaration</h2>
                            <div className='bg-gray-50 p-5 dark:bg-[#363739]'>
                                @interface initialize_ad: NSObject
                            </div>
                        </div>
                    </div>

                    <div className=''>
                        <div className='border-b py-2'>
                            <h2 className='text-2xl'>
                                <p>play_ad</p>
                            </h2>
                        </div>
                        <div className='my-5'>
                            <p>
                                Request an advertisement for the campaign.
                            </p>
                            <h2 className='text-lg my-5 font-bold'>Declaration</h2>
                            <div className='bg-gray-50 p-5 dark:bg-[#363739]'>
                                @interface play_ad: NSObject
                            </div>
                        </div>
                    </div>

                    <div className=''>
                        <div className='border-b py-2'>
                            <h2 className='text-2xl'>
                                <p>finish_ad</p>
                            </h2>
                        </div>
                        <div className='my-5'>
                            <p>
                                Claim the reward after watching an ad in the campaign.
                            </p>
                            <h2 className='text-lg my-5 font-bold'>Declaration</h2>
                            <div className='bg-gray-50 p-5 dark:bg-[#363739]'>
                                @interface finish_ad: NSObject
                            </div>
                        </div>
                    </div>

                    <div className=''>
                        <div className='border-b py-2'>
                            <h2 className='text-2xl'>
                                <p>purchase_pending</p>
                            </h2>
                        </div>
                        <div className='my-5'>
                            <p>
                                Handle pending purchase orders.
                            </p>
                            <h2 className='text-lg my-5 font-bold'>Declaration</h2>
                            <div className='bg-gray-50 p-5 dark:bg-[#363739]'>
                                @interface purchase_pending: NSObject
                            </div>
                        </div>
                    </div>

                    <div className=''>
                        <div className='border-b py-2'>
                            <h2 className='text-2xl'>
                                <p>purchase_confirm</p>
                            </h2>
                        </div>
                        <div className='my-5'>
                            <p>
                                Confirm purchase orders.
                            </p>
                            <h2 className='text-lg my-5 font-bold'>Declaration</h2>
                            <div className='bg-gray-50 p-5 dark:bg-[#363739]'>
                                @interface purchase_confirm: NSObject
                            </div>
                        </div>
                    </div>
                    
                </article>
                
                </main>

            </div>

        </>
    );
}

export default SDKManager