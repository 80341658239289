
import React, { useEffect, useState } from 'react';

function MobileNav( { open, setOpen } ) {
    return (
        <div className={`dark:text-gray-300 dark:bg-[#202123] absolute top-0 left-0 h-screen w-screen bg-white transform ${open ? "-translate-x-0" : "-translate-x-full"} transition-transform duration-300 ease-in-out filter `}>
            <div className="flex items-center justify-center filter drop-shadow-md bg-white px-10"> {/*logo container*/}
                <a className="text-xl font-semibold" href="/">
                    <img
                    className='inline-block mx-2'
                    src="/images/6uologo.png"
                    width={50}
                    height={50}
                    alt="6uo Logo"
                    />
                    6uo Documentation
                </a>
            </div>
            <div className="flex flex-col ml-4 items-center">
                <a href="/">
                    Manual
                </a>
            </div>
            <div className="flex flex-col ml-4 items-center">
                <a href="/scripting">
                    Scripting API
                </a>
            </div>  
            <div className="flex flex-col ml-4 items-center">
                <a href="https://discord.com/invite/MUrV6CRMWT">
                    <img src="https://cdn-icons-png.flaticon.com/512/5968/5968756.png" alt="discord" width={40} height={40} />
                </a>
            </div>  
        </div>
    )
}

export default function Nav() {

    const [onSelect, setSelect] = useState();
    const [open, setOpen] = useState(false);

    useEffect( () => {
        const temp = window.location.pathname;
        var path = temp.split('/');
        path = "/" + path[1];
        setSelect(path);
    }, []);

    return (
        <nav className="dark:bg-[#202123] dark:text-gray-300 sticky top-0 flex justify-between px-10 items-center bg-white drop-shadow-md z-[100]">
            <MobileNav open={open} setOpen={setOpen}/>
            <a className="text-xl font-semibold" href="/">
                <img
                className='inline-block mx-2'
                src="/images/6uologo.png"
                width={50}
                height={50}
                alt="6uo Logo"
                />
                6uo Documentation
            </a>
            <div className="flex items-center">
                <div className="z-50 flex relative w-8 h-8 flex-col justify-between items-center md:hidden" onClick={() => {
                        setOpen(!open)
                    }}>
                    {/* hamburger button */}
                    <span className={`h-1 w-full bg-black rounded-lg transform transition duration-300 ease-in-out ${open ? "rotate-45 translate-y-3.5" : ""}`} />
                    <span className={`h-1 w-full bg-black rounded-lg transition-all duration-300 ease-in-out ${open ? "hidden" : ""}`} />
                    <span className={`h-1 w-full bg-black rounded-lg transform transition duration-300 ease-in-out ${open ? "-rotate-45 -translate-y-3.5" : ""}`} />
                </div>
                <ul className={`${ (onSelect == '/' || onSelect == "/manual" )? "text-[#8ad1c2]": "" } hidden md:flex items-center space-x-6 mx-5`}>
                    <a href="/">
                        Manual
                    </a>
                </ul>
                <ul className={`${ (onSelect == '/scripting')? "text-[#8ad1c2]": "" } hidden md:flex items-center space-x-6 mx-5`}>
                    <a href="/scripting">
                        Scripting API
                    </a>
                </ul>
                <ul className="hidden md:flex items-center space-x-6 ml-10">
                    <a href="https://discord.com/invite/MUrV6CRMWT">
                        <img src="https://cdn-icons-png.flaticon.com/512/5968/5968756.png" alt="discord" width={40} height={40} />
                    </a>
                </ul>
            </div>
        </nav>
    )
}