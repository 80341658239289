
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState } from 'react';

const items=[
    {
        title: "Platform Development",
        itemId: "/"
    },
    {
        title: "Android",
        itemId: "/manual/android",
    },
    {
        title: "Introducing Android",
        itemId: "/manual/android/introducing",
        subNav: [
        {
            title: "requirements and compatibility",
            itemId: "/manual/android/introducing/requirements-and-compatibility"
        },
        ]
    },
    {
        title: "Getting Started",
        itemId: "/manual/android/getting-started",
        subNav: [
        {
            title: "Android environment setup",
            itemId: "/manual/android/getting-started/android-environment-setup"
        },
        {
            title: "SDK settings",
            itemId: "/manual/android/getting-started/sdk-settings"
        }
        ]
    },
    {
        title: "Developing",
        itemId: "/manual/android/developing",
        subNav: [
        {
            title: "testing and debugging",
            itemId: "/manual/android/developing/testing-and-debugging"
        }
        ]
    },
    {
        title: "iOS",
        itemId: "/manual/ios"
    },
    {
        title: "Introducing iOS",
        itemId: "/manual/ios/introducing",
        subNav: [
        {
            title: "requirements and compatibility",
            itemId: "/manual/ios/introducing/requirements-and-compatibility"
        },
        ]
    },
    {
        title: "Getting Started",
        itemId: "/manual/ios/getting-started",
        subNav: [
        {
            title: "iOS environment setup",
            itemId: "/manual/ios/getting-started/ios-environment-setup"
        },
        {
            title: "SDK settings",
            itemId: "/manual/ios/getting-started/sdk-settings"
        }
        ]
    },
    {
        title: "Developing",
        itemId: "/manual/ios/developing",
        subNav: [
        {
            title: "testing and debugging",
            itemId: "/manual/ios/developing/testing-and-debugging"
        }
        ]
    },
];

const findParentItem = (childItemId) => {
    for (const item of items) {
      if (item.subNav) {
        const foundSubItem = item.subNav.find((subItem) => subItem.itemId === childItemId);
        if (foundSubItem) {
          return item;
        }
      }
    }
    return null;
};

export default function Sidebar({onSelect}){

    const navigate = useNavigate();
    const location = useLocation();

    return (
        <>
            <aside id="sidebar-multi-level-sidebar" className="bg-white dark:bg-[#202123] hidden sm:flex pt-10 fixed top-0 left-0 z-40 w-72 h-screen transition-transform border-r-2 dark:border-gray-700" aria-label="Sidebar">
                <div className="h-full px-3 py-4 overflow-y-auto dark:bg-[#202123] pt-10 w-full">
                    <ul className="space-y-2 font-medium">
                        {
                        items.map(item => (
                            !item.subNav ? (
                            <li>
                                <a className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group" href={item.itemId}>
                                    {item.svg}
                                    <span className="ms-3">{item.title}</span>
                                </a>
                            </li>
                            ):(
                            <>
                                <a href={item.itemId} className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" aria-controls={item.itemId} data-collapse-toggle={item.itemId}>
                                    {item.svg}
                                    <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">{item.title}</span>
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                                    </svg>
                                </a>
                                <ul id={item.itemId} class={(onSelect == item.itemId || findParentItem(onSelect)?.itemId == item.itemId )?"py-2 space-y-2":"hidden py-2 space-y-2"}>
                                    {item.subNav.map(subItem => (
                                    <li>
                                        <a href={subItem.itemId} className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                                            {subItem.svg}
                                            {subItem.title}
                                        </a>
                                    </li>
                                    ) )
                                    }
                                </ul>
                            </>
                            )
                        )) 
                        }
                    </ul>
                </div>
            </aside>
        </>
    )
}
