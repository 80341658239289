import React, { useEffect, useState, useMemo } from 'react';
import Sidebar from '../../../components/scripting_sidebar';


function SDKManager(  ) {

    const [onSelect, setSelect] = useState();

    useEffect( () => {
        const temp = window.location.pathname;
        var path = temp;
        setSelect(path);
    }, []);


    return (
        <>

            <Sidebar onSelect={onSelect} />

            <div className="flex">

                <main className='my-20 sm:ml-72 sm:px-10 px-10 w-full'>
                    
                <article className='space-y-8'>
                    
                    <h1 className='text-3xl'>
                        <p>com.sixuogames.mylibrary.</p>
                        SDKManager
                    </h1>

                    <h2 className='text-2xl'>
                        Overview
                    </h2>
                    <p>
                        The &#96;SDKManager&#96; class in the &#96;com.sixuogames.mylibrary&#96; library provides a set of functions to manage the SDK&apos;s functionality, including handling product modes, checking online status, updating and retrieving user balances, login/signup functionality, initializing and interacting with campaigns, and managing in-app purchases.
                    </p>

                    <div className=''>
                        <h2 className='text-2xl bg-gray-100 p-2 dark:bg-[#202123] rounded-t'>
                            <p>Constructor</p>
                        </h2>
                        <p className='bg-gray-50 p-2 dark:bg-[#363739] rounded-b'>
                            SDKManager(Context _context)
                        </p>
                    </div>

                    <div className=''>
                        <h2 className='text-2xl bg-gray-100 p-2 dark:bg-[#202123] rounded-t'>
                            <p>Public Functions</p>
                        </h2>
                        <div className='flex flex-col'>

                            <div className='flex items-center bg-gray-50 p-2 dark:bg-[#363739]'>
                                <div className='w-1/3'>
                                    <p className='mr-4'>SetProd()</p>
                                </div>
                                <div className='w-2/3'>
                                    <p>String</p>
                                    <p className='text-gray-500 dark:text-gray-400'>
                                        This function sets the API URL to product mode instead of the demo.
                                    </p>
                                </div>
                            </div>

                            <div className='flex items-center bg-gray-50 p-2 dark:bg-[#363739]'>
                                <div className='w-1/3'>
                                    <p className='mr-4'>getStatus()</p>
                                </div>
                                <div className='w-2/3'>
                                    <p>String</p>
                                    <p className='text-gray-500 dark:text-gray-400'>
                                        Returns the online status.
                                    </p>
                                </div>
                            </div>

                            <div className='flex items-center bg-gray-50 p-2 dark:bg-[#363739]'>
                                <div className='w-1/3'>
                                    <p className='mr-4'>updateBalance()</p>
                                </div>
                                <div className='w-2/3'>
                                    <p>String</p>
                                    <p className='text-gray-500 dark:text-gray-400'>
                                        Updates the user's balance.
                                    </p>
                                </div>
                            </div>

                            <div className='flex items-center bg-gray-50 p-2 dark:bg-[#363739]'>
                                <div className='w-1/3'>
                                    <p className='mr-4'>getBalance()</p>
                                </div>
                                <div className='w-2/3'>
                                    <p>String</p>
                                    <p className='text-gray-500 dark:text-gray-400'>
                                        Returns the user's balance.
                                    </p>
                                </div>
                            </div>

                            <div className='flex items-center bg-gray-50 p-2 dark:bg-[#363739]'>
                                <div className='w-1/3'>
                                    <p className='mr-4'>login()</p>
                                </div>
                                <div className='w-2/3'>
                                    <p>String</p>
                                    <p className='text-gray-500 dark:text-gray-400'>
                                        Initiates the login process.
                                    </p>
                                </div>
                            </div>

                            <div className='flex items-center bg-gray-50 p-2 dark:bg-[#363739]'>
                                <div className='w-1/3'>
                                    <p className='mr-4'>protect()</p>
                                </div>
                                <div className='w-2/3'>
                                    <p>String</p>
                                    <p className='text-gray-500 dark:text-gray-400'>
                                        Check whether the user is logged in.
                                    </p>
                                </div>
                            </div>

                            <div className='flex items-center bg-gray-50 p-2 dark:bg-[#363739]'>
                                <div className='w-1/3'>
                                    <p className='mr-4'>signup()</p>
                                </div>
                                <div className='w-2/3'>
                                    <p>String</p>
                                    <p className='text-gray-500 dark:text-gray-400'>
                                        Initiate the signup process.
                                    </p>
                                </div>
                            </div>    

                            <div className='flex items-center bg-gray-50 p-2 dark:bg-[#363739]'>
                                <div className='w-1/3'>
                                    <p className='mr-4'>campaign_initialize()</p>
                                </div>
                                <div className='w-2/3'>
                                    <p>String</p>
                                    <p className='text-gray-500 dark:text-gray-400'>
                                        Initialize the campaign.
                                    </p>
                                </div>
                            </div>

                            <div className='flex items-center bg-gray-50 p-2 dark:bg-[#363739]'>
                                <div className='w-1/3'>
                                    <p className='mr-4'>campaign_play_ad()</p>
                                </div>
                                <div className='w-2/3'>
                                    <p>String</p>
                                    <p className='text-gray-500 dark:text-gray-400'>
                                        Request an advertisement for the campaign.
                                    </p>
                                </div>
                            </div>

                            <div className='flex items-center bg-gray-50 p-2 dark:bg-[#363739]'>
                                <div className='w-1/3'>
                                    <p className='mr-4'>campaign_finish_ad()</p>
                                </div>
                                <div className='w-2/3'>
                                    <p>String</p>
                                    <p className='text-gray-500 dark:text-gray-400'>
                                        Claim the reward after watching an ad in the campaign.
                                    </p>
                                </div>
                            </div>

                            <div className='flex items-center bg-gray-50 p-2 dark:bg-[#363739]'>
                                <div className='w-1/3'>
                                    <p className='mr-4'>getAd_session_token()</p>
                                </div>
                                <div className='w-2/3'>
                                    <p>String</p>
                                    <p className='text-gray-500 dark:text-gray-400'>
                                        Return the ad's session token.
                                    </p>
                                </div>
                            </div>

                            <div className='flex items-center bg-gray-50 p-2 dark:bg-[#363739]'>
                                <div className='w-1/3'>
                                    <p className='mr-4'>purchase_pending()</p>
                                </div>
                                <div className='w-2/3'>
                                    <p>String</p>
                                    <p className='text-gray-500 dark:text-gray-400'>
                                        Handle pending purchase orders.
                                    </p>
                                </div>
                            </div>

                            <div className='flex items-center bg-gray-50 p-2 dark:bg-[#363739] rounded-b'>
                                <div className='w-1/3'>
                                    <p className='mr-4'>purchase_confirm()</p>
                                </div>
                                <div className='w-2/3'>
                                    <p>String</p>
                                    <p className='text-gray-500 dark:text-gray-400'>
                                        Confirm purchase orders.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                    
                </article>
                
                </main>

            </div>

        </>
    );
}

export default SDKManager