import { BrowserRouter , Routes , Route } from 'react-router-dom';
import IntroPage from "./scripting/index";
import AndroidPage from "./scripting/android";
import IOSPage from "./scripting/ios";
import { Link } from 'react-router-dom';

function ScriptingIndex(  ) {

    return (
        <>
            <Routes>
				<Route path="" element={<IntroPage />} />
                <Route path="android/*" element={<AndroidPage />} />
                <Route path="ios/*" element={<IOSPage />} />
			</Routes>
        </>
    );
}

export default ScriptingIndex