import React, { useEffect, useState } from 'react';
import Sidebar from '../../../components/scripting_sidebar';

import { Link } from 'react-router-dom';

function IOSHome(  ) {

    const [onSelect, setSelect] = useState();

    useEffect( () => {
        const temp = window.location.pathname;
        var path = temp;
        setSelect(path);
    }, []);

    return (
        <>

            <Sidebar onSelect={onSelect} />

            <div className="flex">

                <main className='my-20 sm:ml-72 sm:px-10 px-10 w-full'>
                    
                    <article className='space-y-8'>

                        <h1 className='text-3xl'>
                            Get Started with iOS
                        </h1>

                        <p>
                            Select a specific category below to explore iOS-specific details:
                        </p>
                        <li>
                            Initialization
                        </li>
                        <li>
                            In-Game Purchase
                        </li>
                        <li>
                            Advertising
                        </li>
                        <li>    
                            Monetize Games
                        </li>
                        <li>
                            Analytics
                        </li>
                        <li>
                            Reporting
                        </li>
                        <p>
                            We are thrilled to support you on your iOS game development journey using the 6uo SDK. Let&apos;s build something amazing together!
                        </p>
                        
                    </article>

                </main>

            </div>

        </>
    );
}

export default IOSHome