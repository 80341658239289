import { BrowserRouter , Routes , Route } from 'react-router-dom';
import HomePage from "./home";
import SDKManager from "./sdkmanager";
import { Link } from 'react-router-dom';

function IOSScripting(  ) {

    return (
        <>
            <Routes>
                <Route path="" element={<HomePage />} />
                <Route path="sdkmanager" element={<SDKManager />} />
			</Routes>
        </>
    );
}

export default IOSScripting;