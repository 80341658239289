
import { useState, useEffect, useMemo } from 'react';
import Sidebar from '../../../components/sidebar';
import {Table} from "../../../components/table";
import { Link } from 'react-router-dom';

export default function AndroidIntroducing() {

	const [onSelect, setSelect] = useState();

    useEffect( () => {
        const temp = window.location.pathname;
        var path = temp;
        setSelect(path);
    }, []);

    const cols = useMemo(
        () => [
			{
				header: 'Topic',
				cell: ({cell}) => <Link to={cell.row.original.topicLink} className="text-blue-500 hover:no-underline underline">{ cell.row.original.topic }</Link>,
				accessorKey: 'topic',
			},
			{
				header: 'Description',
				cell: (row) => row.renderValue(),
				accessorKey: 'description',
			},
        ],
        []
    );

    const dummyData = () => {
        const items = [{
            topic: "iOS requirements and compatibility",
            description: "Lists requirements for 6uo SDK in iOS development.",
            topicLink: "./requirements-and-compatibility",
        }];
        return items;
    };

	return (
		<>
			<Sidebar onSelect={onSelect} />
			
			<div className="flex">

				<main className='my-20 sm:ml-72 sm:px-10 px-10 w-full'>
					
					<h2 className="text-3xl mb-10">Introducing iOS</h2>

                    <p>This section introduces key concepts and workflows that are useful to understand before you integrate 6uoSDK into your Unity application for iOS.</p>


                    <Table data={dummyData()} columns={cols} />

				</main>

			</div>
		</>
	)
}