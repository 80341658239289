
import { BrowserRouter , Routes , Route } from 'react-router-dom';
import IntroPage from "./ios/introducing/index";
import IOSRequirementPage from "./ios/introducing/requirements-and-compatibility";
import GetStartedPage from "./ios/getting-started/index";
import IOSEnvironmentPage from "./ios/getting-started/ios-environment-setup";
import SdkSettings from "./ios/getting-started/sdk-settings";
import DevelopingPage from "./ios/developing/index";
import DevelopingTestingPage from "./ios/developing/testing-and-debugging";
import IOSPage from "./ios";
import NotFoundPage from './NotFound';


export default function iOSRouting() {
	return (
		<>
			<Routes>
				<Route path="" element={<IOSPage />} />
				<Route path="introducing" element={<IntroPage />} />
				<Route path="introducing/requirements-and-compatibility" element={<IOSRequirementPage/>} />

				<Route path="getting-started" element={<GetStartedPage />} />
				<Route path="getting-started/ios-environment-setup" element={<IOSEnvironmentPage/>} />
				<Route path="getting-started/sdk-settings" element={<SdkSettings/>} />

				<Route path="developing" element={<DevelopingPage/>} />
				<Route path="developing/testing-and-debugging" element={<DevelopingTestingPage/>} />

				<Route path="*" element={<NotFoundPage/>} />
			</Routes>
		</>
	)
}
