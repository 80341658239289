
import { BrowserRouter , Routes , Route } from 'react-router-dom';
import IntroPage from "./introducing/index";
import AndroidPage from "./android";
import NotFoundPage from './NotFound';
import AndroidRequirementPage from "./introducing/requirements-and-compatibility";
import GetStartedPage from "./getting-started/index";
import AndroidEnvironmentPage from "./getting-started/android-environment-setup";
import SdkSettings from "./getting-started/sdk-settings";
import DevelopingPage from "./developing/index";
import DevelopingTestingPage from "./developing/testing-and-debugging";


export default function AndroidRouting() {
	return (
		<>
			<Routes>
				<Route path="" element={<AndroidPage />} />
				<Route path="introducing" element={<IntroPage />} />
				<Route path="introducing/requirements-and-compatibility" element={<AndroidRequirementPage/>} />

				<Route path="getting-started" element={<GetStartedPage />} />
				<Route path="getting-started/sdk-settings" element={<SdkSettings/>} />
				<Route path="getting-started/android-environment-setup" element={<AndroidEnvironmentPage/>} />
				

				<Route path="developing" element={<DevelopingPage/>} />
				<Route path="developing/testing-and-debugging" element={<DevelopingTestingPage/>} />

				<Route path="*" element={<NotFoundPage/>} />
			</Routes>
		</>
	)
}
