
import { useState, useEffect, useMemo } from 'react';
import Sidebar from '../../components/sidebar';
import {Table} from "../../components/table";

export default function SdkSettings() {

	const [onSelect, setSelect] = useState();

    useEffect( () => {
        const temp = window.location.pathname;
        var path = temp;
        setSelect(path);
    }, []);

    const cols = useMemo(
        () => [
			{
				header: 'Setting',
				cell: ({cell}) => cell.row.original.setting,
				accessorKey: 'setting',
			},
			{
				header: 'Description',
				cell: (row) => row.renderValue(),
				accessorKey: 'description',
			},
        ],
        []
    );

    const dummyData = () => {
        const items = [
			{
				setting: "Advertising Id",
				description: "Configure the advertising ID to earn revenue while delivering a smooth and engaging ad experience for your players.",
			},
			{
				setting: "Authorization Key",
				description: "Configure the authorization key to earn revenue while selling game items to your players.",
			},
		];

        return items;
    };

	return (
		<>
			<Sidebar onSelect={onSelect} />
		
			<div className="flex">

				<main className='my-20 sm:ml-72 sm:px-10 px-10 w-full'>
					
					<h2 className="text-3xl mb-10">SDK settings</h2>

                    <p> This page details 6uo SDK settings for the Android platform. </p>

                    <h3 className="text-2xl my-5"> 6uo SDK Settings </h3>

					<img src="/images/6uoSDKSettings.jpg" alt="SDK Settigs" />

                    <p className='my-10'>  </p>

					<Table data={dummyData()} columns={cols} />

                    <h4 className="text-xl my-5"> Advertising ID & Authorization Key </h4>

					<p className='my-10'> To obtain the advertising ID and authorization key for your game, </p>

					<p> 1. register with 6uo Partner and sign in to the 6uo Games Partner Program. </p>

					<p> 2. Create your game. </p>

					3. Go to <div className="font-bold inline">Dashboard &gt; &quot;Your Game&quot; &gt; Profit Setting</div>.

					<img src="/images/6uoPartnerProfitSetting.jpg" alt="Partner Profit Setting" width={712} />

				</main>

			</div>
		</>
	)
}