import React, { useEffect, useState } from 'react';
import Sidebar from '../components/sidebar';
import { Link } from 'react-router-dom';

function Home(  ) {

    const [onSelect, setSelect] = useState();

    useEffect( () => {
        const temp = window.location.pathname;
        var path = temp;
        setSelect(path);
    }, []);

    useEffect(() => {
        
    }, []);

    return (
        <>
            <Sidebar onSelect={onSelect} />

            <div className="flex">

                <main className='my-20 sm:ml-72 sm:px-10 px-10 w-full'>
                    
                    <h2 className="text-3xl">Platform Development</h2>

                    <img src="/images/game1.jpg" width={712} alt="zombie game" className="my-10"/>

                    <div className="my-10">
                    &emsp;&emsp;Before delving into the immersive world of the 6uo SDK, it&apos;s crucial to acquire a fundamental understanding of the versatile platforms it seamlessly integrates with. The 6uo SDK is meticulously crafted to empower developers in effectively monetizing their projects on popular mobile platforms, namely Android and iOS. This powerful software development kit provides a comprehensive suite of tools and resources, enabling developers to unlock new revenue streams and maximize their project&apos;s profitability. With the 6uo SDK at your disposal, you can effortlessly harness the potential of Android and iOS platforms to drive monetization strategies, ultimately propelling your projects to new heights of success. Prepare to embark on a transformative journey, as the 6uo SDK revolutionizes the way you monetize your mobile applications on
                    </div>

                    <li className="my-5">
                        <Link to="/manual/android" className='text-blue-500 hover:no-underline underline'>
                            Android
                        </Link>
                    </li>

                    <li className="my-5">
                        <Link to="/manual/ios" className='text-blue-500 hover:no-underline underline'>
                            iOS
                        </Link>
                    </li>

                    <br />
                    <br />

                </main>

            </div>

        </>
    );
}

export default Home