
import { useState, useEffect, useMemo } from 'react';
import Sidebar from '../../components/sidebar';

export default function RequirementsAndCompatibility() {

    const [onSelect, setSelect] = useState();

    useEffect( () => {
        const temp = window.location.pathname;
        var path = temp;
        setSelect(path);
    }, []);

	return (
		<>
            <Sidebar onSelect={onSelect} />
            
			<div className="flex">

				<main className='my-20 sm:ml-72 sm:px-10 px-10 w-full'>
					
					<h2 className="text-3xl mb-10">Android requirements and compatibility</h2>

                    <p> Before embarking on the integration of the 6uo SDK into an Android application in Unity, check Unity&apos;s requirements and compatibility information for Android to make sure you&apos;re aware of any limitations for developing a Unity application for this platform. </p>

                    <h3 className="text-2xl my-5"> Android Support </h3>

                    <p> 6uo SDK supports Android 10.0 (API level 29) and above. </p>

                    <h4 className="text-xl my-5"> Android 10.0 (API level 29) </h4>
                    
                    <p> 6uo SDK doesn&apos;t support API level below 29. To set minimum API level at 29: </p>

                    1. Go to <div className="font-bold inline">Edit &gt; Project Settings</div>

                    <p> 2. In the Project settings window, select the Player tab, then open Android Player Settings </p>

                    3. Open the <div className="font-bold inline">Other Settings</div> fold-out. In the Identification section, select Minimum API level to be Android 10.0:

                    <img src="/images/android_min_api.jpg" alt="Android Min API level" width={712} />

				</main>

			</div>
		</>
	)
}
