

import { useState, useEffect, useMemo } from 'react';
import Sidebar from '../../../components/sidebar';

export default function IosEnvironmentSetup() {

	const [onSelect, setSelect] = useState();

    useEffect( () => {
        const temp = window.location.pathname;
        var path = temp;
        setSelect(path);
    }, []);

	return (
		<>
			<Sidebar onSelect={onSelect} />
			
			<div className="flex">

				<main className='my-20 sm:ml-72 sm:px-10 px-10 w-full'>
					
					<h2 className="text-3xl mb-10">iOS environment setup</h2>

                    <p> To integrate 6uo SDK into your Unity application for iOS, you first need to import 6uo SDK package. </p>

                    <h3 className="text-2xl my-5"> Import package </h3>

                    <p> 1. drag 6uoSDK unitypackage into Unity Assets </p>

                    <p> 2. click import </p>

                    <img src="/images/import6uoSDK.jpg" alt="import Unity Package"/>
                    
				</main>

			</div>
		</>
	)
}