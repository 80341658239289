

import { useState, useEffect, useMemo } from 'react';
import Sidebar from '../../../components/sidebar';

export default function RequirementsAndCompatibility() {

	const [onSelect, setSelect] = useState();

    useEffect( () => {
        const temp = window.location.pathname;
        var path = temp;
        setSelect(path);
    }, []);

	return (
		<>
			<Sidebar onSelect={onSelect} />
			
			<div className="flex">

				<main className='my-20 sm:ml-72 sm:px-10 px-10 w-full'>
					
					<h2 className="text-3xl mb-10">iOS requirements and compatibility</h2>

                    <p> Before embarking on the integration of the 6uo SDK into an iOS application in Unity, check Unity&apos;s requirements and compatibility information for iOS to make sure you&apos;re aware of any limitations for developing a Unity application for this platform. </p>

                    <h3 className="text-2xl my-5"> iOS Support </h3>

					<p> 6uo SDK supports iOS 12.0 and above. </p>
					
				</main>

			</div>
		</>
	)
}
